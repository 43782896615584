"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeCode = exports.PayerAuthenticationAction = exports.isCardDetails = void 0;
const isCardDetails = (val) => val != null &&
    typeof (val) === 'object' &&
    typeof val.suffix === 'string' &&
    typeof val.expirationMonth === 'string' &&
    typeof val.expirationYear === 'string' &&
    typeof val.type === 'string';
exports.isCardDetails = isCardDetails;
var PayerAuthenticationAction;
(function (PayerAuthenticationAction) {
    PayerAuthenticationAction["Authenticate"] = "Authenticate";
    PayerAuthenticationAction["Validate"] = "Validate";
})(PayerAuthenticationAction = exports.PayerAuthenticationAction || (exports.PayerAuthenticationAction = {}));
var ChallengeCode;
(function (ChallengeCode) {
    ChallengeCode["NO_PREFERENCE"] = "01";
    ChallengeCode["NO_CHALLENGE_REQUEST"] = "02";
    ChallengeCode["CHALLENGE_REQUESTED"] = "03";
    ChallengeCode["CHALLENGE_REQUESTED_MANDATE"] = "04";
    // This goes up to 09 see: https://developer.cybersource.com/library/documentation/dev_guides/Payer_Authentication_SCMP_API/html/Topics/Request-Level_Fields.htm
})(ChallengeCode = exports.ChallengeCode || (exports.ChallengeCode = {}));
