


























































import { refundTransaction } from '@ht-lib/accounts-api-client'
import { PaymentAction, ResponseStatus, TransactionResponse } from '@ht-lib/cybersource-models'
import currency from 'currency.js'
import { parseISO, startOfDay, lastDayOfMonth, endOfDay, isAfter } from 'date-fns'
import { Component, Prop } from 'vue-property-decorator'
import Vue from '../VueBase'
import { PaymentInformation } from '../types'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { },
  name: 'Refunds'
})
export default class extends Vue {
  @Prop({ required: true }) payment!: PaymentInformation
  refundAmount = ''
  refundReason = ''
  isRefunding = false

  get history (): TransactionResponse[] { return this.payment.history }

  get amountAuthorized (): number {
    return this.history
      .filter(row => row.action === PaymentAction.Payment && row.status === ResponseStatus.Authorized)
      .reduce<currency>((partial, row) => partial.add(row.amount), currency(0))
      .value
  }

  get amountRefunded (): number {
    return this.history
      .filter(row =>
        (row.action === PaymentAction.Reversal && row.status === ResponseStatus.Reversed) ||
        (row.action === PaymentAction.Refund && row.status === ResponseStatus.Pending)
      )
      .reduce<currency>((partial, row) => partial.add(row.amount), currency(0))
      .value
  }

  get availableToRefund (): number {
    return Math.max(0, currency(this.amountAuthorized).subtract(this.amountRefunded).value)
  }

  get transactionCurrency (): string {
    const first = this.history[0]
    return first == null ? '' : first.currency
  }

  get isCardExpired (): boolean {
    const expYear = parseInt(this.payment.cardExpirationYear ?? '-1', 10)
    const expMonth = parseInt(this.payment.cardExpirationMonth ?? '-1', 10)
    if (isNaN(expYear) || expYear === -1 || isNaN(expMonth) || expMonth === -1) { return true }

    // Remember that javascript dates are zero indexed
    const expiryDate = endOfDay(lastDayOfMonth(new Date(expYear, expMonth - 1, 1)))

    return isAfter(new Date(), expiryDate)
  }

  get targetTransaction (): TransactionResponse | undefined {
    return this.history.find(row => row.action === PaymentAction.Payment && row.status === ResponseStatus.Authorized)
  }

  get canPartialRefund (): boolean {
    if (this.isAdyenRefund) { return true }

    const target = this.targetTransaction
    if (target == null) { return false }

    const midnight = startOfDay(new Date())
    const transactionTime = parseISO(target.recorded.toString())
    return transactionTime < midnight
  }

  get isAdyenRefund (): boolean {
    return this.availableToRefund === 0
  }

  get canAdyenRefund (): boolean {
    return this.targetTransaction?.paymentToken != null || this.targetTransaction?.customerToken != null
  }

  get canDoRefund (): boolean {
    return (this.isAdyenRefund && this.canAdyenRefund) || this.availableToRefund > 0
  }

  created (): void {
    this.refundAmount = this.canPartialRefund ? '0' : this.availableToRefund.toString()
  }

  amountChange (): void {
    const suffix = this.refundAmount.endsWith('.')
      ? '.'
      : this.refundAmount.endsWith('.0')
        ? '.0'
        : ''
    this.refundAmount = currency(this.refundAmount).value.toString() + suffix
  }

  async doRefundClick (): Promise<void> {
    console.log('Doing refund')
    this.isRefunding = true

    try {
      const first = this.targetTransaction

      if (first == null) {
        this.$q.dialog({
          title: 'Refund failed',
          message: 'We could not find a transaction to refund',
          ok: 'OK'
        })
        return
      }

      const amount = currency(this.refundAmount).value
      if (!this.isAdyenRefund && amount > this.availableToRefund) {
        this.$q.dialog({
          title: 'Refund failed',
          message: 'We cannot refund more than the original transaction amount',
          ok: 'OK'
        })
        return
      }

      if (amount <= 0) {
        this.$q.dialog({
          title: 'Refund failed',
          message: 'Refund amount must be positive',
          ok: 'OK'
        })
        return
      }

      let reason: string | null = this.refundReason.trim()
      if (reason.length === 0) {
        reason = null
      }

      console.log('validation passed', amount, reason)
      const response = await refundTransaction({
        transaction: {
          id: first.id,
          amount: amount.toString()
        },
        reason,
        adyenRefund: this.isAdyenRefund
      })

      console.log('emitting new transaction')

      this.$emit('new-transaction', first.id)

      const { error, message, transactions } = response

      if (error) {
        this.$q.dialog({
          title: 'Refund failed',
          message,
          ok: 'OK'
        })
      } else if (transactions == null) {
        this.$q.dialog({
          title: 'Refund status unknown',
          message: 'An unexpected response was returned from the endpoint, please check the result of this action in the cybersource virtual terminal',
          ok: 'OK'
        })
      } else if (!transactions.void && !transactions.reverse && !transactions.refund && !transactions.credit) {
        this.$q.dialog({
          title: 'Refund status unknown',
          message: 'No response was returned, please check the result of this action in the cybersource virtual terminal',
          ok: 'OK'
        })
      } else {
        this.$q.dialog({
          title: 'Refund Completed',
          message: 'The refund has been completed',
          ok: 'OK'
        })
      }

      console.log('all done')
    } catch (e) {
      console.error('Exception while doing refund', e)
      this.$q.dialog({
        title: 'Refund Error',
        message: 'There was an error while attempting the refund. Please check the result of this action in the cybersource virtual terminal.',
        ok: 'OK'
      })
    } finally {
      this.isRefunding = false
    }
  }
}
