"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationType = exports.PaymentSolution = exports.RunEnvironment = void 0;
var RunEnvironment;
(function (RunEnvironment) {
    RunEnvironment["SANDBOX"] = "apitest.cybersource.com";
    RunEnvironment["PRODUCTION"] = "api.cybersource.com";
    // BOA_SANDBOX = "bankofamerica.environment.sandbox",
    // BOA_PRODUCTION = "bankofamerica.environment.production",
    // IDC_SANDBOX = "apitest.cybersource.com",
    // IDC_PRODUCTION = "api.in.cybersource.com",
})(RunEnvironment = exports.RunEnvironment || (exports.RunEnvironment = {}));
var PaymentSolution;
(function (PaymentSolution) {
    PaymentSolution["None"] = "NO_PAYMENT_SOLUTION";
    PaymentSolution["Microform"] = "";
    PaymentSolution["VisaCheckout"] = "visacheckout";
    PaymentSolution["ApplePay"] = "001";
    PaymentSolution["CybersourceInAppSolution"] = "004";
    PaymentSolution["Masterpass"] = "005";
    PaymentSolution["AndroidPay"] = "006";
    PaymentSolution["ChasePay"] = "007";
    PaymentSolution["SamsungPay"] = "008";
    PaymentSolution["GooglePay"] = "012";
})(PaymentSolution = exports.PaymentSolution || (exports.PaymentSolution = {}));
var AuthenticationType;
(function (AuthenticationType) {
    AuthenticationType["JWT"] = "jwt";
    AuthenticationType["HTTP"] = "http_signature";
})(AuthenticationType = exports.AuthenticationType || (exports.AuthenticationType = {}));
