"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isOkResponse = exports.ResponseStatus = void 0;
var ResponseStatus;
(function (ResponseStatus) {
    ResponseStatus["Authorized"] = "AUTHORIZED";
    ResponseStatus["PartialAuthorized"] = "PARTIAL_AUTHORIZED";
    ResponseStatus["Pending"] = "PENDING";
    ResponseStatus["Reversed"] = "REVERSED";
    ResponseStatus["Voided"] = "VOIDED";
    ResponseStatus["Accepted"] = "ACCEPTED";
    ResponseStatus["Rejected"] = "REJECTED";
    ResponseStatus["Completed"] = "COMPLETED";
    ResponseStatus["Failed"] = "FAILED";
    ResponseStatus["PendingReview"] = "PENDING_REVIEW";
    ResponseStatus["Challenge"] = "CHALLENGE";
    ResponseStatus["AuthenticationSuccessful"] = "AUTHENTICATION_SUCCESSFUL";
    ResponseStatus["AuthenticationFailed"] = "AUTHENTICATION_FAILED";
    ResponseStatus["AuthorizedPendingReview"] = "AUTHORIZED_PENDING_REVIEW";
    ResponseStatus["AuthorizedRiskDeclined"] = "AUTHORIZED_RISK_DECLINED";
    ResponseStatus["Declined"] = "DECLINED";
    ResponseStatus["PendingAuthentication"] = "PENDING_AUTHENTICATION";
    ResponseStatus["InvalidRequest"] = "INVALID_REQUEST";
    ResponseStatus["ServerError"] = "SERVER_ERROR";
})(ResponseStatus = exports.ResponseStatus || (exports.ResponseStatus = {}));
const isOkResponse = (input) => {
    if (input == null || typeof input !== 'object') {
        return false;
    }
    const okResponse = input;
    return typeof okResponse.id === 'string' &&
        Object.values(ResponseStatus).includes(okResponse.status) &&
        okResponse.status !== ResponseStatus.InvalidRequest &&
        okResponse.status !== ResponseStatus.ServerError;
};
exports.isOkResponse = isOkResponse;
