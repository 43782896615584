










import { TransactionResponse } from '@ht-lib/cybersource-models'
import { Component, Prop } from 'vue-property-decorator'
import { PaymentInformation } from '../types'
import Vue from '../VueBase'
import Refund from './Refund.vue'
import PaymentHistoryTable from './PaymentHistoryTable.vue'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { Refund, PaymentHistoryTable },
  name: 'PaymentHistory'
})
export default class extends Vue {
  @Prop({ required: true }) payment!: PaymentInformation

  showRefundScreen = false

  get history (): TransactionResponse[] { return this.payment.history }
}
