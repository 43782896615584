"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentAction = exports.isTransactionResponse = void 0;
function isTransactionResponse(value) {
    const data = value;
    return (typeof data.action === 'string' && // Enum
        typeof data.amount === 'string' &&
        typeof data.currency === 'string' &&
        typeof data.id === 'string' &&
        (typeof data.originalPaymentId === 'string' || data.originalPaymentId === null) &&
        (typeof data.paymentToken === 'string' || data.paymentToken === null) &&
        data.recorded instanceof Date &&
        typeof data.reference === 'string' &&
        typeof data.status === 'string' // Enum
    );
}
exports.isTransactionResponse = isTransactionResponse;
var PaymentAction;
(function (PaymentAction) {
    PaymentAction["Payment"] = "Payment";
    PaymentAction["Void"] = "Void";
    PaymentAction["Reversal"] = "Reversal";
    PaymentAction["Refund"] = "Refund";
    PaymentAction["Credit"] = "Credit";
})(PaymentAction = exports.PaymentAction || (exports.PaymentAction = {}));
