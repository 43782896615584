import { render, staticRenderFns } from "./PaymentHistory.vue?vue&type=template&id=705de838&scoped=true&lang=html&"
import script from "./PaymentHistory.vue?vue&type=script&lang=ts&"
export * from "./PaymentHistory.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705de838",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog});
