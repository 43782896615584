

































import { PaymentSearchInformationWithHistory, TransactionResponse } from '@ht-lib/cybersource-models'
import { searchTransactions } from '@ht-lib/accounts-api-client'
import { Component, Prop } from 'vue-property-decorator'
import Vue from '../VueBase'
import { PaymentInformation } from '../types'
import PaymentHistory from './PaymentHistory.vue'
import PaymentHistoryTable from './PaymentHistoryTable.vue'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { PaymentHistory, PaymentHistoryTable },
  name: 'PaymentInformation'
})
export default class extends Vue {
  @Prop({ type: Array, required: true }) payments!: PaymentSearchInformationWithHistory[]

  get paymentInformation (): PaymentInformation[] {
    return this.payments.map(p => {
      return { ...p.information, history: p.history }
    })
  }

  linkedTransactions: TransactionResponse[] = []
  lookupEmail = ''

  get showLinkedTransactionsDialog (): boolean {
    return this.linkedTransactions.length > 0
  }

  set showLinkedTransactionsDialog (input: boolean) {
    if (!input) { this.linkedTransactions = [] }
  }

  pagination = {
    rowsPerPage: 50
  }

  tableHeaders = [
    { name: 'paymentId', label: 'PSP', field: 'paymentId', sortable: true, required: true, align: 'left' },
    { name: 'firstName', label: 'First Name', field: 'firstName', sortable: true, required: true, align: 'left' },
    { name: 'middleName', label: 'Middle Name', field: 'middleName', sortable: true, required: true, align: 'left' },
    { name: 'lastName', label: 'Last Name', field: 'lastName', sortable: true, required: true, align: 'left' },
    { name: 'address1', label: 'House / Street', field: 'address1', sortable: true, required: true, align: 'left' },
    { name: 'address2', label: 'Address 2', field: 'address2', sortable: true, required: true, align: 'left' },
    { name: 'address3', label: 'Address 3', field: 'address3', sortable: true, required: true, align: 'left' },
    { name: 'address4', label: 'Address 4', field: 'address4', sortable: true, required: true, align: 'left' },
    { name: 'locality', label: 'Town / City', field: 'locality', sortable: true, required: true, align: 'left' },
    { name: 'administrativeArea', label: 'County / State', field: 'administrativeArea', sortable: true, required: true, align: 'left' },
    { name: 'postalCode', label: 'Postal Code', field: 'postalCode', sortable: true, required: true, align: 'left' },
    { name: 'country', label: 'Country', field: 'country', sortable: true, required: true, align: 'left' },
    { name: 'email', label: 'Email', field: 'email', sortable: true, required: true, align: 'left' },
    { name: 'phoneNumber', label: 'Phone Number', field: 'phoneNumber', sortable: true, required: true, align: 'left' },
    { name: 'cardExpirationMonth', label: 'Exp. Month', field: 'cardExpirationMonth', sortable: true, required: true, align: 'left' },
    { name: 'cardExpirationYear', label: 'Exp. Year', field: 'cardExpirationYear', sortable: true, required: true, align: 'left' },
    { name: 'cardLastFourDigits', label: 'Last 4 Digits', field: 'cardLastFourDigits', sortable: true, required: true, align: 'left' }
  ]

  getAddress (row: PaymentInformation): string {
    const fields = [
      row.address1,
      row.address2,
      row.address3,
      row.address4,
      row.locality,
      row.administrativeArea,
      row.postalCode,
      row.country
    ]

    return fields.filter(field => typeof field === 'string' && field.length > 0).join(', ')
  }

  async doReleatedSearch (email: string | null | undefined): Promise<void> {
    if (typeof email !== 'string' || email.length === 0) { return }

    try {
      const response = await searchTransactions({ email })
      if (response.error) {
        this.$q.dialog({
          title: 'Related transaction lookup failed',
          message: response.message,
          ok: 'OK'
        })
      } else {
        this.lookupEmail = email
        this.linkedTransactions = response.results.flatMap(i => i.history)
      }
    } catch (e) {
      console.error('Exception while searching for related transactions', e)
      this.$q.dialog({
        title: 'Related transaction lookup error',
        message: 'There was an error while attempting the lookup. Please try again.',
        ok: 'OK'
      })
    }
  }
}
