"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isConsumerAuthenticationInformation = void 0;
const isConsumerAuthenticationInformation = (input) => {
    if (typeof input !== 'object' || input === null) {
        return false;
    }
    const info = input;
    return typeof info.accessToken === 'string' && typeof info.deviceDataCollectionUrl === 'string' && typeof info.referenceId === 'string';
};
exports.isConsumerAuthenticationInformation = isConsumerAuthenticationInformation;
