
























































import { Component, Prop } from 'vue-property-decorator'
import Vue from '../VueBase'
import { getTransactionHistory, searchTransactions } from '@ht-lib/accounts-api-client'
import { PaymentSearchRequest, PaymentSearchInformationWithHistory } from '@ht-lib/cybersource-models'
import PaymentInformation from './PaymentInformation.vue'
import { cloneDeep } from 'lodash'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { PaymentInformation },
  name: 'TransactionSearchBox'
})
export default class extends Vue {
  @Prop({ type: String, default: null }) paymentId?: string

  isSearching = false

  defaultSearchParams: Partial<PaymentSearchRequest> = {
    paymentId: '',
    firstName: '',
    lastName: '',
    middleName: '',
    nameSuffix: '',
    title: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    locality: '',
    administrativeArea: '',
    postalCode: '',
    country: '',
    district: '',
    buildingNumber: '',
    email: '',
    emailDomain: '',
    phoneNumber: '',
    phoneType: '',
    cardExpirationMonth: '',
    cardExpirationYear: '',
    cardLastFourDigits: '',
    amount: ''
  }

  searchParams: Partial<PaymentSearchRequest> = {}

  results: PaymentSearchInformationWithHistory[] = []

  inputClass (additional = 'col-sm-6 col-xs-12'): string { return `q-my-sm q-px-sm q-px-sm ${ additional }` }

  async mounted (): Promise<void> {
    this.resetSearchParams()
    if (this.paymentId != null) {
      this.searchParams.paymentId = this.paymentId
      await this.doSearch()
    }
  }

  async doSearch (): Promise<void> {
    this.isSearching = true
    this.results = []

    try {
      this.sanitizeSearchParams()
      const response = await searchTransactions(this.searchParams)
      if (response.error) {
        this.$q.dialog({
          title: 'Search failed',
          message: response.message,
          ok: 'OK'
        })
      } else {
        this.results = response.results
      }
    } catch (e) {
      console.error('Exception while searching', e)
      this.$q.dialog({
        title: 'Search Error',
        message: 'There was an error while attempting the search. Please try again.',
        ok: 'OK'
      })
    } finally {
      this.isSearching = false
    }
  }

  sanitizeSearchParams (): void {
    const searchParams = this.searchParams as { [key: string]: string | null | undefined }
    for (const key of Object.keys(searchParams)) {
      if (typeof searchParams[key] !== 'string') continue
      searchParams[key] = searchParams[key]?.trim()
    }
  }

  async refreshTransactionHistory (paymentId: string): Promise<void> {
    const target = this.results.find(r => r.information.paymentId === paymentId)
    if (target == null) { return }

    target.history = await getTransactionHistory(paymentId)
  }

  resetSearchParams (): void {
    this.searchParams = cloneDeep(this.defaultSearchParams)
  }

  resetForm (): void {
    this.resetSearchParams()
    this.results = []
  }
}
